import { type Static, Type } from '@sinclair/typebox'
import { integer, pgTable, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { overrideType, tableToSchema } from './shared'
import { promptVersion } from './prompt-version'

export const promptVersionMessage = pgTable('prompt_version_message', {
    id: uuid('id').primaryKey().defaultRandom(),
    content: text('content'),
    markdownContent: text('markdown_content'),
    role: varchar('role', { length: 32 }).notNull(),
    promptVersionId: uuid('prompt_version_id').notNull().references(() => promptVersion.id, { onDelete: 'cascade' }),
    index: integer('index').default(0).notNull(),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
})

export const promptVersionMessageRelations = relations(promptVersionMessage, ({ one }) => ({
    promptVersion: one(promptVersion, {
        fields: [promptVersionMessage.promptVersionId],
        references: [promptVersion.id],
    }),
}))

const { selectSchema, insertSchema } = tableToSchema(promptVersionMessage)

export const roleEnum = Type.Enum({
    user: 'user',
    assistant: 'assistant',
    system: 'system',
    function: 'function',
})

export const selectPromptVersionMessageSchema = overrideType(selectSchema, {
    role: roleEnum,
})

export const createPromptVersionMessageSchema = overrideType(
    Type.Omit(insertSchema, ['createdAt', 'updatedAt', 'deletedAt', 'id']),
    {
        role: roleEnum,
    },
)

export const simpleMessageSchema = Type.Pick(createPromptVersionMessageSchema, ['content', 'role'])

export const updatePromptVersionMessageSchema = Type.Partial(createPromptVersionMessageSchema)

export type SimpleMessageSchema = Static<typeof simpleMessageSchema>
export type PromptVersionMessage = Static<typeof selectPromptVersionMessageSchema>
export type CreatePromptVersionMessagePayload = Static<typeof createPromptVersionMessageSchema>
export type UpdatePromptVersionMessagePayload = Partial<Static<typeof updatePromptVersionMessageSchema>>
