import type { Static } from '@sinclair/typebox'
import { index, pgTable, real, timestamp, uuid } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { score } from './score'
import { generation } from './generation'
import { tableToSchema } from './shared'

export const prediction = pgTable('prediction', {
    id: uuid('id').primaryKey().defaultRandom(),
    value: real('value'),
    generationId: uuid('generation_id').notNull().references(() => generation.id, { onDelete: 'cascade' }),
    scoreId: uuid('score_id').notNull().references(() => score.id, { onDelete: 'cascade' }),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
}, (table) => {
    return {
        idxPredictionScoreId: index('idx_prediction_score_id').on(table.scoreId),
        idxPredictionGenerationId: index('idx_prediction_generation_id').on(table.generationId),
    }
})
export const predictionRelations = relations(prediction, ({ one }) => ({
    score: one(score, {
        fields: [prediction.scoreId],
        references: [score.id],
    }),
    generation: one(generation, {
        fields: [prediction.generationId],
        references: [generation.id],
    }),
}))

const { insertSchema, selectSchema } = tableToSchema(prediction)

export const predictionSelectSchema = selectSchema

export type Prediction = Static<typeof predictionSelectSchema>
export type CreatePrediction = Static<typeof insertSchema>
