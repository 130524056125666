import { pgTable, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { type Static, Type } from '@sinclair/typebox'
import { createInsertSchema, createSelectSchema } from 'drizzle-typebox'
import { provider } from './provider'
import { workspace } from './workspace'

export const providerCredential = pgTable('provider_credential', {
    id: uuid('id').primaryKey().defaultRandom(),
    workspaceId: uuid('workspace_id').notNull().references(() => workspace.id, { onDelete: 'cascade' }),
    providerId: uuid('provider_id').notNull().references(() => provider.id, { onDelete: 'cascade' }),
    token: varchar('token', { length: 256 }).notNull(),
    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
})

export const providerCredentialRelations = relations(providerCredential, ({ one }) => ({
    workspace: one(workspace, {
        fields: [providerCredential.workspaceId],
        references: [workspace.id],
    }),
    provider: one(provider, {
        fields: [providerCredential.providerId],
        references: [provider.id],
    }),
}))

export const selectProviderCredentialSchema = createSelectSchema(providerCredential)
const insertProviderCredentialSchema = createInsertSchema(providerCredential)
export const createProviderCredentialSchema = Type.Pick(insertProviderCredentialSchema, ['token', 'workspaceId', 'providerId'])
export const updateProviderCredentialSchema = Type.Partial(Type.Pick(insertProviderCredentialSchema, ['token']))

export type ProviderCredential = Static<typeof selectProviderCredentialSchema>
export type CreateProviderCredentialPayload = Static<typeof createProviderCredentialSchema>
export type UpdateProviderCredentialPayload = Static<typeof updateProviderCredentialSchema>
