export function withoutKeys<T, K extends (keyof T)[]>(obj: T, keys: K): Omit<T, K[number]> {
    const result: any = {}
    for (const key in obj) {
        if (!keys.includes(key as any))
            result[key] = obj[key]
    }
    return result
}

export function deepWithoutKeys<T, K extends string[]>(obj: T, keys: K): Omit<T, K[number]> {
    if (obj === null || typeof obj !== 'object')
        return obj

    if (Array.isArray(obj)) {
        const copy: any[] = []
        for (let i = 0; i < obj.length; i++)
            copy[i] = deepWithoutKeys(obj[i], keys)

        return copy as unknown as T
    }

    if (obj instanceof Object) {
        const copy: { [key: string]: any } = {}
        for (const attr in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, attr)) {
                if (!keys.includes(attr))
                    copy[attr] = deepWithoutKeys(obj[attr], keys)
            }
        }
        return copy as T
    }

    return obj
}
