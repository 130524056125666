import { pgTable, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { type Static, Type } from '@sinclair/typebox'
import { tableToSchema } from './shared'

export const user = pgTable('user', {
    id: uuid('id').primaryKey().defaultRandom(),
    email: varchar('email', { length: 256 }).unique().notNull(),
    name: varchar('name', { length: 256 }),
    avatar_url: text('avatar_url'),
    password: varchar('password', { length: 256 }),
    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
})

const { selectSchema, insertSchema } = tableToSchema(user)

export const selectUserSchema = Type.Omit(selectSchema, ['password'])
export const createUserSchema = insertSchema

export type User = Static<typeof selectSchema>
export type CreateUserPayload = Static<typeof createUserSchema>
