import { type Static, Type } from '@sinclair/typebox'
import type { AnyPgColumn } from 'drizzle-orm/pg-core'
import { jsonb, pgTable, real, smallint, timestamp, uuid } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { overrideType, tableToSchema } from './shared'
import { promptVersion } from './prompt-version'
import { prompt } from './prompt'
import { evaluator } from './evaluator'
import { predictionSelectSchema } from './prediction'

export const score = pgTable('score', {
    id: uuid('id').primaryKey().defaultRandom(),
    value: smallint('value'),
    promptId: uuid('prompt_id').notNull().references(() => prompt.id, { onDelete: 'cascade' }),
    promptVersionId: uuid('prompt_version_id').references(() => promptVersion.id, { onDelete: 'cascade' }),
    accuracy: real('accuracy'),
    precision: real('precision'),
    recall: real('recall'),
    cutoff: real('cutoff'),
    coefficients: jsonb('coefficients').default({}).$type<Record<string, number>>(),

    // The 'evaluatorId' and 'mainScoreId' fields are used to identify a preview score and link it with its parents.
    // A preview score is a score that represents a potential main score after a evaluator toggle.
    evaluatorId: uuid('evaluator_id').references(() => evaluator.id, { onDelete: 'cascade' }),
    mainScoreId: uuid('main_score_id').references(
        (): AnyPgColumn => score.id,
        {
            onDelete: 'cascade',
        },
    ),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
})

export const scoreRelations = relations(score, ({ one }) => ({
    prompt: one(prompt, {
        fields: [score.promptId],
        references: [prompt.id],
    }),
    promptVersion: one(promptVersion, {
        fields: [score.promptVersionId],
        references: [promptVersion.id],
    }),
    score: one(score, {
        fields: [score.mainScoreId],
        references: [score.id],
    }),
    evaluator: one(evaluator, {
        fields: [score.evaluatorId],
        references: [evaluator.id],
    }),
}))

const { selectSchema, insertSchema } = tableToSchema(score)

export const selectScoreSchema = overrideType(
    selectSchema,
    {
        previewScores: Type.Optional(Type.Array(selectSchema)),
        predictions: Type.Optional(Type.Array(predictionSelectSchema)),
    },
)

export const getLatestScore = Type.Pick(selectSchema, ['promptId'])

export type CreateScore = Static<typeof insertSchema>
export type Score = Static<typeof selectScoreSchema>
