import { type Static, Type } from '@sinclair/typebox'
import { relations } from 'drizzle-orm'
import { index, pgTable, smallint, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-typebox'
import { project } from './project'
import { workspace } from './workspace'

export const prompt = pgTable('prompt', {
    id: uuid('id').primaryKey().defaultRandom(),
    title: varchar('title', { length: 256 }).notNull(),
    projectId: uuid('project_id').notNull().references(() => project.id, { onDelete: 'cascade' }),
    inferencesPercentage: smallint('inferences_percentage').notNull().default(10),

    workspaceId: uuid('workspace_id').notNull().references(() => workspace.id, { onDelete: 'cascade' }),
    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
}, (table) => {
    return {
        idxPromptWorkspaceId: index('idx_prompt_workspace_id').on(table.workspaceId),
    }
})

export const selectPromptSchema = createSelectSchema(prompt)

export const enrichedPromptSchema = Type.Composite([
    selectPromptSchema,
    Type.Object({
        favorited: Type.Optional(Type.Boolean()),
        hasPromptVersions: Type.Optional(Type.Boolean()),
        hasTestCases: Type.Optional(Type.Boolean()),
        hasEvaluators: Type.Optional(Type.Boolean()),
        hasLiveGenerations: Type.Optional(Type.Boolean()),
    }),
])

const insertPromptSchemaAll = createInsertSchema(prompt)
export const insertPromptSchema = Type.Pick(insertPromptSchemaAll, ['title', 'projectId', 'workspaceId'])
export const updatePromptSchema = Type.Partial(Type.Pick(insertPromptSchemaAll, ['title', 'projectId', 'inferencesPercentage']))

export const promptRelations = relations(prompt, ({ one }) => ({
    project: one(project, {
        fields: [prompt.projectId],
        references: [project.id],
    }),
    workspace: one(workspace, {
        fields: [prompt.workspaceId],
        references: [workspace.id],
    }),
}))

export type Prompt = Static<typeof enrichedPromptSchema>
export type CreatePromptPayload = Static<typeof insertPromptSchema>
export type UpdatePromptPayload = Static<typeof updatePromptSchema>
