import { type Static, Type } from '@sinclair/typebox'
import { pgEnum, pgTable, smallint, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { tableToSchema } from './shared'
import { evaluatorRun } from './evaluator-run'
import { promptVersion } from './prompt-version'
import type { CompletionEventEnvironment } from './completion-event'

export const evaluatorRunsBatchStatusEnum = pgEnum('evaluator_runs_batch_status', ['pending', 'running', 'completed', 'failed'])

export const evaluatorRunsBatch = pgTable('evaluator_runs_batch', {
    id: uuid('id').primaryKey().defaultRandom(),
    status: evaluatorRunsBatchStatusEnum('status').default('pending'),
    batchSize: smallint('batch_size').notNull(),
    completedRunsCount: smallint('completed_runs').notNull().default(0),
    retryCount: smallint('retry_count').notNull().default(0),
    promptVersionId: uuid('prompt_version_id').notNull().references(() => promptVersion.id, { onDelete: 'cascade' }),
    environment: varchar('environment').notNull().$type<CompletionEventEnvironment>(),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
})

export const evaluatorRunsBatchRelations = relations(evaluatorRunsBatch, ({ one, many }) => ({
    promptVersion: one(promptVersion, {
        fields: [evaluatorRunsBatch.promptVersionId],
        references: [promptVersion.id],
    }),
    evaluatorRuns: many(evaluatorRun),
}))

const { insertSchema, selectSchema } = tableToSchema(evaluatorRunsBatch)

export const missingRunsSchema = Type.Object(
    {
        evaluatorId: Type.String(),
        generationId: Type.String(),
        status: Type.Enum({ error: 'error', pending: 'pending' }),
    },
)

export const selectEvaluatorRunsBatchSchema = selectSchema
export const createEvaluatorRunsBatchSchema = Type.Pick(insertSchema, ['promptVersionId'])

export type EvaluatorRunsBatch = typeof evaluatorRunsBatch.$inferSelect
export type CreateEvaluatorRunsBatchPayload = Static<typeof createEvaluatorRunsBatchSchema>
export type MissingRun = Static<typeof missingRunsSchema>
