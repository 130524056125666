import { type Static, Type } from '@sinclair/typebox'
import { pgTable, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { workspace } from './workspace'
import { providerCredential } from './provider-credential'
import { tableToSchema } from './shared'

export const project = pgTable('project', {
    id: uuid('id').primaryKey().defaultRandom(),
    title: varchar('title', { length: 256 }).notNull(),
    workspaceId: uuid('workspace_id').notNull().references(() => workspace.id, { onDelete: 'cascade' }),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
})

export const projectRelations = relations(project, ({ one, many }) => ({
    workspace: one(workspace, {
        fields: [project.workspaceId],
        references: [workspace.id],
    }),
    providerCredential: many(providerCredential),
}))

const { selectSchema, insertSchema } = tableToSchema(project)

export const selectProjectSchema = selectSchema
export const createProjectSchema = Type.Pick(insertSchema, ['title', 'workspaceId'])
export const updateProjectSchema = Type.Partial(createProjectSchema)

export type Project = Static<typeof selectProjectSchema>
export type CreateProjectPayload = Static<typeof createProjectSchema>
export type UpdateProjectPayload = Partial<Static<typeof createProjectSchema>>
