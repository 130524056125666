import { Value } from '@sinclair/typebox/value'
import { type Prompt, selectPromptSchema } from 'tela-api/src/database/schemas/prompt'

export function usePrompts() {
    const log = useLog()
    const { api } = useAPI()
    const prompts = useState<Prompt[] | null>('prompts', () => null)
    const notification = useToast()
    const { currentWorkspace } = useWorkspaces()

    const currentPromptId = useState<string>('current-prompt-id', () => '')
    const currentPrompt = computed(() => prompts.value?.find(i => i.id === currentPromptId.value) || null)

    async function fetchPrompts(options?: {
        projectId?: string
        includeStats?: boolean
    }) {
        const $query: Record<string, string | boolean> = {}

        if (currentWorkspace.value?.id)
            $query.workspaceId = currentWorkspace.value.id

        if (options?.projectId)
            $query.projectId = options.projectId

        if (options?.includeStats)
            $query.includeStats = true

        const promptsResponse = await api().prompt.get({ $query })

        if (promptsResponse.error || !Array.isArray(promptsResponse.data)) {
            notification.add({
                title: 'Error fetching prompts',
                description: promptsResponse.error?.message,
                color: 'red',
            })
            return
        }

        prompts.value = promptsResponse.data.map(toPrompt)
        return prompts.value
    }

    async function fetchPromptById(promptId: string) {
        const response = await api().prompt[promptId].get()
        if (response.error) {
            throw new Error('PROMPT_NOT_FOUND')
        }

        if (!prompts.value)
            prompts.value = []

        if (!prompts.value.find(p => p.id === promptId))
            prompts.value?.push(toPrompt(response.data))

        return response.data
    }

    async function newPrompt(projectId: string, title: string) {
        const {
            promptVersions,
            promptVersionMessages,
            setCurrentPromptVersion,
            toPromptVersion,
            toPromptVersionMessage,
            getDefaultPromptVersionConfiguration,
        } = usePromptVersions()

        const newPromptResponse = await api().prompt.post({
            title,
            projectId,
            workspaceId: currentWorkspace.value!.id,
            version: {
                title: 'Initial version',
                content: '',
                configuration: getDefaultPromptVersionConfiguration(),
                variables: [],
                draft: true,
                messages: {
                    content: initialVersionMessage,
                    markdownContent: initialVersionMessage,
                    role: 'system',
                },
            },
        })

        if (newPromptResponse.error) {
            notification.add({
                title: 'Error creating canvas',
                description: newPromptResponse.error.message,
                color: 'red',
            })
            return
        }

        const { version, ...prompt } = newPromptResponse.data
        if (version) {
            const { message, ...newVersion } = version

            promptVersions.value = [...(promptVersions.value ?? []), toPromptVersion(newVersion)]
            setCurrentPromptVersion(newVersion.id)

            if (message)
                promptVersionMessages.value = [...(promptVersionMessages.value ?? []), toPromptVersionMessage(message)]
        }

        prompts.value = [...(prompts.value ?? []), toPrompt(prompt)]

        return toPrompt(prompt)
    }

    function getPromptById(promptId: string) {
        return computed(() => prompts.value?.find(i => i.id === promptId))
    }

    function setCurrentPrompt(promptId: string) {
        currentPromptId.value = promptId
    }

    const updatePromptRemote = useDebounceFn(async (promptId: string, prompt: Partial<Prompt>) => {
        const updatedPrompt = await api().prompt[promptId].patch(prompt)

        if (updatedPrompt.error) {
            notification.add({
                title: 'Error updating prompt',
                description: updatedPrompt.error.message,
                color: 'red',
            })
        }
    }, 750)

    async function updatePrompt(promptId: string, prompt: Partial<Prompt>) {
        const promptIdx = prompts.value!.findIndex(i => i.id === promptId)
        if (promptIdx === -1)
            throw new Error('Prompt not found')

        prompts.value![promptIdx] = { ...prompts.value![promptIdx], ...prompt, updatedAt: new Date() }

        await updatePromptRemote(promptId, prompt)
    }

    async function deletePrompt(prompt: Prompt) {
        const deletedPrompt = await api().prompt[prompt.id].delete()

        if (deletedPrompt.error) {
            notification.add({
                title: 'Error deleting prompt',
                description: deletedPrompt.error.message,
                color: 'red',
            })
            return
        }

        prompts.value = prompts.value!.filter(i => i.id !== prompt.id)
    }

    async function toggleFavoritedPrompt(prompt: Prompt) {
        const idx = prompts.value!.findIndex(i => i.id === prompt.id)
        if (idx === -1)
            return

        const initialValue = prompts.value![idx].favorited
        prompts.value![idx] = { ...prompts.value![idx], favorited: !initialValue }

        const favoriteResponse = await api().prompt[prompt.id].favorite.post()

        if (favoriteResponse.error) {
            notification.add({
                title: 'Error favoriting prompt',
                description: favoriteResponse.error.message,
                color: 'red',
            })
            prompts.value![idx] = { ...prompts.value![idx], favorited: initialValue }
        }

        if (!initialValue) {
            notification.add({
                title: 'Success!',
                description: `Canvas ${prompt.title} is now listed on Favorites section`,
                timeout: 10_000,
            })
        }
    }

    async function duplicatePrompt(promptId: string) {
        log.info('Duplicating prompt', { promptId })
        const duplicatedPrompt = await api().prompt[promptId].duplicate.post()

        if (duplicatedPrompt.error) {
            log.error('Error duplicating prompt', duplicatedPrompt.error)
            notification.add({
                title: 'Error duplicating prompt',
                description: duplicatedPrompt.error.message,
                color: 'red',
            })
            return
        }

        prompts.value = [...(prompts.value ?? []), toPrompt(duplicatedPrompt.data)]

        notification.add({
            title: 'Canvas Duplicated',
            description: `You can visit the ${duplicatedPrompt.data.title}`,
            color: 'positive',
            actions: [{
                label: 'View',
                click: () => navigateTo(`/app/prompt/${duplicatedPrompt.data.id}`),
            }],
        })
    }

    return {
        prompts,
        fetchPrompts,
        newPrompt,
        deletePrompt,
        setCurrentPrompt,
        currentPrompt,
        currentPromptId,
        updatePrompt,
        updatePromptRemote,
        fetchPromptById,
        getPromptById,
        toggleFavoritedPrompt,
        duplicatePrompt,
    }
}

function toPrompt(prompt: any) {
    return Value.Convert(selectPromptSchema, prompt) as Prompt
}
