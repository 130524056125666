import { type Static, Type } from '@sinclair/typebox'
import { boolean, jsonb, pgEnum, pgTable, smallint, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { createInsertSchema, createSelectSchema } from 'drizzle-typebox'
import { evaluator, selectEvaluatorSchema } from './evaluator'
import { evaluatorKeys, overrideType } from './shared'
import { generation } from './generation'
import { promptVersion } from './prompt-version'
import { evaluatorRunsBatch } from './evaluator-runs-batch'

/**
 * evaluatorRunStatus is an enumeration that represents the status of an evaluator run.
 *
 * - 'waiting': The run is on the queue and waiting to start.
 * - 'running': We are trying to get a score for this run.
 * - 'completed': We got a score successfully and the execution is ended.
 * - 'invalid': We tried several times to get a score for the run but couldn't, execution ended. This probably means that the evaluator prompt is bad.
 * - 'error': Something unexpected happened.
 */
export const evaluatorRunStatus = pgEnum('evaluator_run_status', ['running', 'waiting', 'completed', 'error', 'invalid'])
export const evaluatorRun = pgTable('evaluator_run', {
    id: uuid('id').primaryKey().defaultRandom(),
    input: text('input').notNull(),
    score: smallint('score'),
    status: evaluatorRunStatus('status').notNull().default('waiting'),
    evaluatorId: uuid('evaluator_id').notNull().references(() => evaluator.id, { onDelete: 'cascade' }),
    evaluatorConfigurationHash: varchar('evaluator_configuration_hash'),
    generationId: uuid('generation_id').references(() => generation.id, { onDelete: 'cascade' }),
    isLiveRun: boolean('is_live_run').notNull().default(false),
    promptVersionId: uuid('prompt_version_id').references(() => promptVersion.id, { onDelete: 'cascade' }),
    outputMetadata: jsonb('output_metadata').default({}),
    evaluatorRunsBatchId: uuid('evaluator_runs_batch_id').references(() => evaluatorRunsBatch.id, { onDelete: 'cascade' }),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
})

export const evaluatorRunRelations = relations(evaluatorRun, ({ one }) => ({
    evaluator: one(evaluator, {
        fields: [evaluatorRun.evaluatorId],
        references: [evaluator.id],
    }),
    generation: one(generation, {
        fields: [evaluatorRun.generationId],
        references: [generation.id],
    }),
    promptVersion: one(promptVersion, {
        fields: [evaluatorRun.promptVersionId],
        references: [promptVersion.id],
    }),
    evaluatorRunsBatch: one(evaluatorRunsBatch, {
        fields: [evaluatorRun.evaluatorRunsBatchId],
        references: [evaluatorRunsBatch.id],
    }),
}))

export const rawSelectEvaluatorRun = createSelectSchema(evaluatorRun)
const insertEvaluatorRun = createInsertSchema(evaluatorRun)

export const insertEvaluatorRunSchema = Type.Omit(insertEvaluatorRun, ['createdAt', 'updatedAt', 'id', 'deletedAt', 'status'])
export const updateEvaluatorRunSchema = Type.Partial(Type.Pick(rawSelectEvaluatorRun, ['id', 'score', 'outputMetadata', 'status']))

export const fetchEvaluatorRunsSchema = Type.Object({
    generationId: Type.Optional(Type.String()),
    generationIsArchived: Type.Optional(Type.Boolean()),
    promptId: Type.Optional(Type.String()),
    hasFeedback: Type.Optional(Type.Boolean()),
    hasGeneration: Type.Optional(Type.Boolean()),
    evaluatorId: Type.Optional(Type.String()),
    active: Type.Optional(Type.Boolean()),
})

export const selectEvaluatorRun = Type.Composite(
    [
        rawSelectEvaluatorRun,
        Type.Object({ evaluatorKey: evaluatorKeys }),
    ],
)

export const runData = Type.Object({
    promptVersionId: Type.Optional(Type.String()),
    generationId: Type.Optional(Type.String()),
    evaluatorId: Type.String({ format: 'uuid' }),
    input: Type.String(),
    prompt: Type.String(),
    expectedOutput: Type.Optional(Type.Any()),
    isLiveRun: Type.Boolean(),
    evaluatorRunsBatchId: Type.Optional(Type.String({ format: 'uuid' })),
    variables: Type.Record(Type.String(), Type.Any()),
    evaluator: selectEvaluatorSchema,
})

export const selectLengthEvaluatorRun = overrideType(selectEvaluatorRun, {
    outputMetadata: Type.Object({
        length: Type.Number(),
        operation: Type.String(),
    }),
})
export const selectSimilarityEvaluatorRun = overrideType(selectEvaluatorRun, {
    outputMetadata: Type.Object({
        similarity: Type.Number(),
    }),
})
export const selectModelGradedEvaluatorRun = overrideType(selectEvaluatorRun, {
    outputMetadata: Type.Object({
        completion: Type.Number(),
        badGeneration: Type.Boolean(),
    }),
})
export const selectRegexEvaluatorRun = overrideType(selectEvaluatorRun, {
    outputMetadata: Type.Object({
        validRules: Type.Array(Type.Object({
            regex: Type.String(),
            attributePath: Type.Any(),
        })),
        invalidRules: Type.Array(Type.Object({
            regex: Type.String(),
            attributePath: Type.Any(),
        })),
    }),
})

export type RunData = Static<typeof runData>
export type RegexEvaluatorRun = Static<typeof selectRegexEvaluatorRun>
export type ModelGradedEvaluatorRun = Static<typeof selectModelGradedEvaluatorRun>
export type LengthEvaluatorRun = Static<typeof selectLengthEvaluatorRun>
export type SimilarityEvaluatorRun = Static<typeof selectSimilarityEvaluatorRun>
export type EvaluatorRun = Static<typeof selectEvaluatorRun>
export type UpdateEvaluatorRunPayload = Static<typeof updateEvaluatorRunSchema>
export type CreateEvaluatorRunPayload = Static<typeof insertEvaluatorRun>
