import { Value } from '@sinclair/typebox/value'
import type { CreatePromptVersionWithMessagePayload, UpdatePromptVersionWithMessagesPayload } from 'tela-api/src/database/schemas/populated-schemas'
import type { PromptVersion, PromptVersionConfiguration, PromptVersionWithMessages } from 'tela-api/src/database/schemas/prompt-version'
import { selectPromptVersionSchema } from 'tela-api/src/database/schemas/prompt-version'
import type { PromptVersionMessage } from 'tela-api/src/database/schemas/prompt-version-message'
import { selectPromptVersionMessageSchema } from 'tela-api/src/database/schemas/prompt-version-message'
import { detailedDiff } from 'deep-object-diff'
import { parseVariablesFromMarkdown } from './editor/variable'
import { deepWithoutKeys } from '~/server/utils/without-keys'

export const initialVersionMessage = 'You\'re a helpful AI assistant.'

export function usePromptVersions() {
    const log = useLog()

    const { api } = useAPI()
    const notification = useToast()
    const uiState = useUIState()
    const { currentPrompt } = usePrompts()

    const promptVersions = useState<PromptVersion[] | null>('prompt-versions', () => null)
    const promptVersionMessages = useState<PromptVersionMessage[] | null>('prompt-versions-messages', () => null)
    const currentPromptVersionId = useState<string | null>('current-prompt-version-id', () => null)
    const structuredOutputSchemaTitleError = useState<string | boolean>('structured-output-schema-title-error', () => false)

    const isGeneratingNewVersionName = useState<boolean>('is-generating-new-version-name', () => false)
    const isBumpingPromptVersion = useState<boolean>('is-bumping-prompt-version', () => false)

    const currentPromptVersionConfiguration = computed(() => promptVersions.value?.find(p => p.id === currentPromptVersionId.value)?.configuration)
    const currentVersionIsEmpty = computed(() => {
        if (!currentPromptVersionId.value)
            return true

        const promptVersion = getPromptVersion(currentPromptVersionId.value)
        const promptMessageContent = promptVersion.messages[0].markdownContent?.trim()
        return promptVersion
            && (promptMessageContent === initialVersionMessage || !promptMessageContent)
            && !promptVersion.configuration.structuredOutput?.enabled
            && !promptVersion.configuration.functions?.length
    })

    const previousVersion = computed(() => {
        const currentIdx = promptVersions.value?.findIndex(p => p.id === currentPromptVersionId.value)
        const version = promptVersions.value?.[Math.max(currentIdx! - 1, 0)]
        const messages = promptVersionMessages.value?.filter(m => m.promptVersionId === version?.id) || []

        return {
            ...version,
            messages,
        } as PromptVersionWithMessages
    })

    function getDiffBetweenVersions(promptVersionA: PromptVersionWithMessages, promptVersionB: PromptVersionWithMessages) {
        const changes = detailedDiff(
            deepWithoutKeys(promptVersionA, ['createdAt', 'updatedAt', 'id', 'title', 'draft', 'promoted', 'promptVersionId']),
            deepWithoutKeys(promptVersionB, ['createdAt', 'updatedAt', 'id', 'title', 'draft', 'promoted', 'promptVersionId']),
        )

        if (
            Object.keys({
                ...changes.added,
                ...changes.deleted,
                ...changes.updated,
            }).length === 0
        ) {
            return null
        }

        return changes
    }

    const diffFromPreviousVersion = computed(() => {
        const currentVersion = getPromptVersion(currentPromptVersionId.value!)
        if (!previousVersion.value)
            return null

        return getDiffBetweenVersions(
            previousVersion.value,
            currentVersion,
        )
    })

    const currentPromptVersionTableProperties = computed(() => {
        const list: Array<{ title: string, path: string, schemaPath: string, description?: string, dynamic?: boolean }> = []

        function findTable(properties: Record<string, any>, path = '', schemaPath = '') {
            for (const key in properties) {
                if (properties[key].metadata?.isTable) {
                    const tableObj = {
                        title: key,
                        schemaPath: schemaPath ? `${schemaPath}.${key}` : key,
                        path: path ? `${path}.${key}` : key,
                        description: properties[key].description,
                        dynamic: Boolean(properties[key].metadata?.dynamicColumns),
                    }
                    list.push(tableObj)
                }
                else if (properties[key].type === 'object') {
                    findTable(
                        properties[key].properties,
                        path ? `${path}.${key}` : key,
                        schemaPath ? `${schemaPath}.${key}.properties` : `${key}.properties`,
                    )
                }
                else if (properties[key].type === 'array' && properties[key].items.type === 'object') {
                    findTable(
                        properties[key].items.properties,
                        path ? `${path}.${key}` : key,
                        schemaPath ? `${schemaPath}.${key}.items.properties` : `${key}.items.properties`,
                    )
                }
            }
        }

        findTable(currentPromptVersionConfiguration.value?.structuredOutput?.schema?.properties ?? {})

        return list
    })

    function setCurrentPromptVersion(promptVersionId: string | null) {
        currentPromptVersionId.value = promptVersionId
    }

    const isFetchingPromptVersions = useState<boolean>('fetching-prompt-versions', () => false)
    async function fetchPromptVersions(promptId: string) {
        isFetchingPromptVersions.value = true
        const promptVersionList = await api()['prompt-version'].get({ $query: { promptId } })

        if (promptVersionList.error || !Array.isArray(promptVersionList.data)) {
            notification.add({
                title: 'Error fetching prompt versions',
                description: promptVersionList.error?.message,
                color: 'red',
            })
            isFetchingPromptVersions.value = false
            return
        }

        promptVersions.value = promptVersionList.data.map(({ messages: _, ...promptVersion }) => toPromptVersion(promptVersion))
        const messageList = promptVersionList.data.flatMap(({ messages }) => messages.map(m => toPromptVersionMessage(m)))

        promptVersionMessages.value = messageList
        isFetchingPromptVersions.value = false

        return promptVersions.value
    }

    function getPromptVersion(promptVersionId: string) {
        const promptVersion = promptVersions.value?.find(p => p.id === promptVersionId)
        if (!promptVersion)
            throw new Error('Cannot find prompt version')

        const messages = promptVersionMessages.value?.filter(m => m.promptVersionId === promptVersionId) || []

        return {
            ...promptVersion,
            messages,
        }
    }

    function getPromptVersionsByPromptId(promptId: string) {
        return promptVersions.value?.filter(p => p.promptId === promptId)
    }
    async function createDraftVersion(options: { promptVersionUpdate?: PromptVersionWithMessages; messagesUpdate?: PromptVersionMessage[] } = {}) {
        if (uiState.craft.isCreatingDraftVersion.value)
            return

        uiState.craft.isCreatingDraftVersion.value = true

        const resolvedPromptVersion = options.promptVersionUpdate ?? getPromptVersion(currentPromptVersionId.value!)

        if (!resolvedPromptVersion)
            return

        const newPromptVersionTitle = resolvedPromptVersion.title

        const resolvedPromptVersionMessages = resolvedPromptVersion.messages.map(({ role, content, markdownContent }, index: number) => {
            let message = {
                role,
                content,
                markdownContent,
            } as Record<string, any>

            if (options.messagesUpdate && options.messagesUpdate[index])
                message = options.messagesUpdate[index]

            return {
                role: message.role,
                content: message.content,
                markdownContent: message.markdownContent,
                index,
                id: crypto.randomUUID(),
            }
        })

        const resolvedVariables = Array.from(resolvedPromptVersionMessages.reduce((acc, message) => {
            const messageVariables = parseVariablesFromMarkdown(message.markdownContent ?? '')
            messageVariables.forEach(variable => acc.add(variable))
            return acc
        }, new Set<string>()))

        // We create the new version with the current state
        const newPromptVersionPayload: CreatePromptVersionWithMessagePayload = {
            id: crypto.randomUUID(),
            title: newPromptVersionTitle,
            content: resolvedPromptVersion.content,
            promptId: resolvedPromptVersion.promptId,
            configuration: resolvedPromptVersion.configuration,
            variables: resolvedVariables,
            messages: resolvedPromptVersionMessages,
            draft: true,
            markdownContent: resolvedPromptVersion.markdownContent,
        }

        const newPromptVersion = toPromptVersion(withoutKeys(newPromptVersionPayload, ['messages']))

        const newPromptVersionMessages = (newPromptVersionPayload.messages as PromptVersionMessage[]).map(message => ({
            ...message,
            createdAt: new Date(),
            updatedAt: new Date(),
            promptVersionId: newPromptVersion.id,
        }))

        promptVersionMessages.value!.push(...newPromptVersionMessages)

        promptVersions.value!.push({
            ...newPromptVersion,
            createdAt: new Date(),
            updatedAt: new Date(),
        })

        // Switch to new version
        setCurrentPromptVersion(newPromptVersion.id)

        // Creates the draft version
        log.debug('Creating draft version', { newPromptVersion })
        const newPromptVersionResponse = await api({ retry: 5 })['prompt-version'].post(newPromptVersionPayload)

        if (newPromptVersionResponse.error) {
            notification.add({
                title: 'Error creating prompt version',
                description: newPromptVersionResponse.error?.message,
                color: 'red',
            })
            return
        }

        uiState.craft.isCreatingDraftVersion.value = false

        flushOperationsWaitingForDraftVersion(newPromptVersion.id)
    }

    const _operationsWaitingForDraftVersion = useState<({
        dedupeKey?: string
        createdAt?: number
        operation: (promptVersionId: string) => any
    })[]>('wait-for-draft-version-operation-list', () => [])

    const operationsWaitingForDraftVersion = computed(() => {
        const groupedOperations = {} as Record<string, any>
        for (const operation of _operationsWaitingForDraftVersion.value) {
            const key = operation.dedupeKey as string
            if (!groupedOperations[key])
                groupedOperations[key] = []

            groupedOperations[key].push(operation)
        }

        // Order operations by createdAt
        for (const key in groupedOperations)
            groupedOperations[key] = groupedOperations[key].sort((a: any, b: any) => a.createdAt! - b.createdAt!)

        return groupedOperations
    })

    function waitForDraftVersion<Callback extends (promptVersionId: string) => any>(callback: Callback, options?: {
        dedupeKey?: string
    }): Promise<ReturnType<Callback>> {
        return new Promise<ReturnType<Callback>>((resolve) => {
            // TODO: write tests here
            if (uiState.craft.isCreatingDraftVersion.value) {
                _operationsWaitingForDraftVersion.value.push({
                    dedupeKey: options?.dedupeKey ?? randomId(),
                    createdAt: Date.now(),
                    operation: (promptVersionId: string) => {
                        resolve(callback(promptVersionId))
                    },
                })
            }
            else {
                resolve(callback(currentPromptVersionId.value!))
            }
        })
    }

    async function flushOperationsWaitingForDraftVersion(promptVersionId: string) {
        for (const operations of Object.values(operationsWaitingForDraftVersion.value)) {
            // If there are more than one operation with the same dedupe key, run only the most recent one
            if (operations.length > 1) {
                operations.pop()?.operation(promptVersionId)
                continue
            }

            for (const operation of operations)
                operation.operation(promptVersionId)
            await wait(10)
        }

        _operationsWaitingForDraftVersion.value = []
    }

    /**
     * If there is a previous version, we want to create a new version
     * with the current state
     */
    async function bumpPromptVersion() {
        if (!currentPromptVersionId.value)
            return

        const promptVersion = getPromptVersion(currentPromptVersionId.value)
        if (!promptVersion.draft)
            return

        isBumpingPromptVersion.value = true

        const promptVersionPatch: UpdatePromptVersionWithMessagesPayload = {
            draft: false,
        }

        if (previousVersion.value && diffFromPreviousVersion.value) {
            const title = await generatePromptVersionName(diffFromPreviousVersion.value)
            promptVersionPatch.title = title
        }

        // Then we update the previous version with the previous state
        await updatePromptVersion(currentPromptVersionId.value, promptVersionPatch, true)

        isBumpingPromptVersion.value = false
    }

    /**
     * Generates a prompt version name based on the changes from the previous version
     */
    async function generatePromptVersionName(changes: any) {
        if (!changes)
            return

        isGeneratingNewVersionName.value = true

        const response = await api({ retry: 5 }).assistant['generate-prompt-version-name'].post({ diff: JSON.stringify(changes) })
        if (response.error || !response.data) {
            const { currentPrompt } = usePrompts()
            log.error('Error generating prompt version name', response.error ?? { error: 'No data returned' })
            const promptVersionList = promptVersions.value!.filter(p => p.promptId === currentPrompt.value!.id)
            return `Version #${promptVersionList.length + 1}`
        }

        return response.data
    }

    const updatePromptVersionRemote = useDebounceFn(async (promptVersionId: string, promptVersion: Partial<PromptVersion>) => {
        log.debug('Updating prompt version remote', { promptVersionId, promptVersion, promptId: currentPrompt.value?.id })
        const updatedPromptVersion = await api()['prompt-version'][promptVersionId].patch(promptVersion)

        if (updatedPromptVersion.error) {
            notification.add({
                title: 'Error updating prompt version',
                description: updatedPromptVersion.error.message,
                color: 'red',
            })
        }
    }, 750)

    async function updatePromptVersion(promptVersionId: string, promptVersion: UpdatePromptVersionWithMessagesPayload, syncRemote = true) {
        if (!promptVersions.value)
            return

        const promptVersionToBePatched: PromptVersionWithMessages = getPromptVersion(promptVersionId)
        if (!promptVersionToBePatched)
            return

        const patchedPromptVersion = {
            ...promptVersionToBePatched,
            ...promptVersion,
        } as PromptVersionWithMessages

        if (!promptVersionToBePatched?.draft) {
            log.info('Create draft version', { promptVersionId, promptVersion })
            return await createDraftVersion({
                promptVersionUpdate: patchedPromptVersion,
            })
        }

        promptVersions.value = promptVersions.value.map((p) => {
            if (p.id !== promptVersionId)
                return p

            return patchedPromptVersion
        })

        if (syncRemote) {
            waitForDraftVersion((newPromptVersionId: string) => {
                updatePromptVersionRemote(newPromptVersionId, promptVersion)
            }, { dedupeKey: 'update-prompt-version-remote' })
        }
    }

    async function updateCurrentPromptVersion(patch: UpdatePromptVersionWithMessagesPayload, syncRemote = true) {
        const promptVersionId = currentPromptVersionId.value
        if (!promptVersionId)
            return

        if (syncRemote) {
            waitForDraftVersion((newPromptVersionId: string) => {
                updatePromptVersion(newPromptVersionId, patch, syncRemote)
            }, { dedupeKey: 'update-current-prompt-version' })
        }
    }

    async function updatePromptVersionConfiguration(promptVersionId: string, configuration: Partial<PromptVersionConfiguration>) {
        const promptVersion = getPromptVersion(promptVersionId)
        if (!promptVersion)
            return

        const newConfiguration = {
            ...promptVersion.configuration,
            ...configuration,
        }

        await updatePromptVersion(promptVersionId, {
            configuration: newConfiguration,
        })
    }

    async function createEmptyPromptVersion(promptId: string) {
        if (!promptVersions.value)
            promptVersions.value = []

        const promptVersionList = promptVersions.value.filter(p => p.promptId === promptId)

        const newPromptVersionPayload: CreatePromptVersionWithMessagePayload = {
            title: `Version #${promptVersionList.length + 1}`,
            content: '',
            promptId,
            configuration: getDefaultPromptVersionConfiguration(),
            markdownContent: '',
            variables: [],
            messages: {
                content: initialVersionMessage,
                markdownContent: initialVersionMessage,
                role: 'system',
            },
        }

        const newPromptVersionResponse = await api()['prompt-version'].post(newPromptVersionPayload)

        if (newPromptVersionResponse.error) {
            notification.add({
                title: 'Error creating prompt version',
                description: newPromptVersionResponse.error?.message,
                color: 'red',
            })
            return
        }

        const { messages: message, ...version } = newPromptVersionResponse.data
        const newPromptVersion = toPromptVersion(version)
        promptVersions.value.push(newPromptVersion)
        if (message) {
            const newMessage = toPromptVersionMessage(message)
            promptVersionMessages.value = [...(promptVersionMessages.value ?? []), newMessage]
        }

        setCurrentPromptVersion(newPromptVersion.id)
        return newPromptVersion
    }

    async function deletePromptVersion(promptVersionId: string) {
        const promptVersion = getPromptVersion(promptVersionId)

        if (promptVersions.value?.length === 1) {
            log.warn('Trying to delete the only prompt version. Aborting.', { promptVersionId })
            return
        }

        const messagesToDelete = promptVersionMessages.value!.filter(m => m.promptVersionId === promptVersion.id)
        messagesToDelete.forEach((m) => {
            deletePromptVersionMessage(m.id)
        })

        promptVersions.value = promptVersions.value!.filter(p => p.id !== promptVersion.id)
        if (currentPromptVersionId.value === promptVersion.id)
            setCurrentPromptVersion(promptVersions.value![promptVersions.value!.length - 1]!.id)

        const deletedPromptVersion = await api()['prompt-version'][promptVersion.id].delete()

        if (deletedPromptVersion.error) {
            notification.add({
                title: 'Error deleting prompt version',
                description: deletedPromptVersion.error.message,
                color: 'red',
            })
        }
    }

    async function initializePromptVersions() {
        const { currentPrompt } = usePrompts()
        watch(currentPrompt, async (newPrompt, oldPrompt) => {
            if (!newPrompt)
                return

            if (!oldPrompt || (newPrompt.id !== oldPrompt?.id && oldPrompt?.id))
                fetchPromptVersions(newPrompt.id)
        }, { immediate: true })
    }

    async function promotePromptVersion(promptVersionId: string) {
        const { data, error } = await api()['prompt-version'][promptVersionId].promote.post()

        if (error) {
            notification.add({
                title: 'Error promoting prompt version',
                description: error.message,
                color: 'red',
            })
            return
        }

        const { messages: _, ...promotedVersion } = data
        promptVersions.value = promptVersions.value!.map((p) => {
            if (p.id === promptVersionId)
                return toPromptVersion(promotedVersion)

            if (p.promoted && p.id !== promptVersionId) {
                return {
                    ...p,
                    promoted: false,
                }
            }

            return p
        })
    }

    function getDefaultPromptVersionConfiguration(): PromptConfiguration {
        return {
            model: 'gpt-4o',
            type: 'chat',
            temperature: 0,
        }
    }

    function getPromptVersionMessage(messageId: string) {
        return computed(() => promptVersionMessages.value!.find(m => m.id === messageId))
    }

    const updatePromptVersionMessageRemote = useDebounceFn(async (messageId: string, message: Partial<PromptVersionMessage>) => {
        waitForDraftVersion(async () => {
            const response = await api({ retry: 2 })['prompt-version-message'][messageId].patch(message)
            log.debug('Update prompt version message remote', { messageId, message, response })

            if (response.error) {
                notification.add({
                    title: 'Error updating prompt version message',
                    description: response.error.message,
                    color: 'red',
                })
            }

            return toPromptVersionMessage(response.data)
        }, { dedupeKey: 'update-prompt-version-message-remote' })
    }, 750)

    async function updateCurrentPromptVersionMessage(messageIndex: number, message: Partial<PromptVersionMessage>, syncRemote = true) {
        if (!promptVersionMessages.value)
            return

        const promptVersion = computed(() => getPromptVersion(currentPromptVersionId.value!))

        if (!promptVersion.value.draft) {
            log.debug('Message update triggered creation of draft version')

            // Get current messages with the content updated
            const resolvedPromptVersionMessages = promptVersionMessages.value!
                .filter(({ promptVersionId }) => promptVersionId === promptVersion.value.id)
                .map((m, index) => {
                    if (messageIndex === index) {
                        return {
                            ...m,
                            ...message,
                        }
                    }

                    return m
                })

            log.debug('createDraftVersion', { resolvedPromptVersionMessages })

            await createDraftVersion({
                messagesUpdate: resolvedPromptVersionMessages as PromptVersionMessage[],
            })

            return
        }

        const promptVersionMessage = promptVersion.value.messages[messageIndex]

        if (!promptVersionMessage)
            useNotification().error('Error updating prompt version message', 'Couldn\'t find the message')

        const idx = promptVersionMessages.value.findIndex(m => m.id === promptVersionMessage.id)
        if (idx === -1)
            return

        const updatedVersion = {
            ...promptVersionMessages.value[idx],
            ...message,
        }

        promptVersionMessages.value[idx] = updatedVersion

        if (syncRemote && promptVersionMessage.id)
            updatePromptVersionMessageRemote(promptVersionMessage.id, message)

        return updatedVersion
    }

    async function deletePromptVersionMessage(messageId: string) {
        promptVersionMessages.value = promptVersionMessages.value!.filter(m => m.id !== messageId)

        const response = await api()['prompt-version-message'][messageId].delete()

        if (response.error) {
            notification.add({
                title: 'Error deleting prompt version message',
                description: response.error.message,
                color: 'red',
            })
        }
    }

    function getLastVersionUpdateTime() {
        const messages = promptVersionMessages.value?.filter(m => m.promptVersionId === currentPromptVersionId.value) ?? []
        const version = getPromptVersion(currentPromptVersionId.value!)

        const promptUpdateTime = new Date(currentPrompt.value?.updatedAt ?? 0).getTime()
        const messageUpdateTime = new Date(messages.filter(Boolean).sort((a, b) => new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime())[0].updatedAt!).getTime()
        const versionUpdateTime = new Date(version.updatedAt!).getTime()

        return Math.max(promptUpdateTime, messageUpdateTime, versionUpdateTime)
    }

    return {
        promptVersions,
        promptVersionMessages,
        isFetchingPromptVersions,
        isGeneratingNewVersionName,
        setCurrentPromptVersion,
        fetchPromptVersions,
        createEmptyPromptVersion,
        getPromptVersionsByPromptId,
        bumpPromptVersion,
        getPromptVersion,
        updatePromptVersion,
        updateCurrentPromptVersion,
        deletePromptVersion,
        updatePromptVersionConfiguration,
        initializePromptVersions,
        getDefaultPromptVersionConfiguration,
        updateCurrentPromptVersionMessage,
        deletePromptVersionMessage,
        getPromptVersionMessage,
        promotePromptVersion,
        currentPromptVersionId,
        toPromptVersion,
        toPromptVersionMessage,
        structuredOutputSchemaTitleError,
        diffFromPreviousVersion,
        isBumpingPromptVersion,
        currentPromptVersionConfiguration,
        waitForDraftVersion,
        createDraftVersion,
        getLastVersionUpdateTime,
        currentVersionIsEmpty,
        currentPromptVersionTableProperties,
    }
}

function toPromptVersion(promptVersion: any) {
    return Value.Convert(selectPromptVersionSchema, promptVersion) as PromptVersion
}

function toPromptVersionMessage(promptVersionMessage: any) {
    return Value.Convert(selectPromptVersionMessageSchema, promptVersionMessage) as PromptVersionMessage
}

export type PromptConfiguration = {
    model: string
    temperature?: number
    type: 'chat' | 'completion'
}
