import { Mark, markInputRule, markPasteRule, mergeAttributes } from '@tiptap/core'

const inputRegex = /(?<!\{)\{([a-zA-Z_]\w*)\}(?!\})/
const pasteRegex = /(?<!\{)\{([a-zA-Z_]\w*)\}(?!\})/g

export function parseVariablesFromMarkdown(markdownContent: string): string[] {
    const variables = Array.from(
        new Set(
            markdownContent
                .match(/\{([a-zA-Z_]\w*)\}/g)
                ?.map(variable => variable.replace(/\{|\}/g, ''))
                .filter(Boolean)
                || [],
        ),
    )
    return variables
}

/**
 * Highlights variables in the editor when
 * the format {{ variable }} is used.
 */
export const VariableMark = Mark.create({
    name: 'variable',

    addOptions() {
        return {
            HTMLAttributes: {
                class: 'variable',
            },
        }
    },

    addAttributes() {
        return {
            variable: {
                default: null,
                parseHTML: (element) => {
                    const variable = element.getAttribute('data-variable')
                    return { variable }
                },
                renderHTML: (attributes) => {
                    return {
                        'data-variable': attributes.variable,
                    }
                },
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'variable[data-variable]',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['variable', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands() {
        return {
            toggleVariable: () => ({ commands }: any) => {
                return commands.toggleMark('variable') as any
            },
        } as any
    },

    onUpdate() {
        const { state } = this.editor

        // Go through all nodes and find the ones that are valid variable names
        state.doc.descendants((node, pos) => {
            if (node.type.name === 'text') {
                const textContent = node.textContent
                const match = textContent.match(inputRegex)
                if (match?.index) {
                    // Get the position of the match
                    const matchPosition = pos + match.index

                    // Get the last selection position
                    const { from, to } = state.selection

                    this.editor
                        .chain()
                        .setTextSelection({ from: matchPosition, to: matchPosition + match[0].length })
                        .toggleMark('variable')
                        // Return to the previous selection
                        .setTextSelection({ from, to })
                        .run()

                    return true
                }
            }
        })
    },

    addKeyboardShortcuts() {
        const handleExitVariable = (char = ' '): any => {
            const { state } = this.editor
            const { from, to } = state.selection

            if (from === to) {
                let insideVariable = false
                state.doc.nodesBetween(from - 1, to, (node) => {
                    const variable = node.marks.find(mark => mark.type.name === 'variable')
                    if (variable)
                        insideVariable = true
                })

                if (insideVariable) {
                    return this.editor
                        .chain()
                        .setTextSelection({ from: to, to })
                        .unsetMark('variable')
                        .insertContent(char)
                        .run()
                }
            }

            return false
        }

        const nonVariableCharacters = ['Space', 'Enter', '/', '?', '*', '+', '-', '=', '<', '>', '|', '&', '!', '@', '#', '$', '%', '^', '(', ')', '[', ']', '{', '}', ':', ';', ',', '.', '"', '\'', '`']

        const overloadedKeyboardShortcuts = nonVariableCharacters.reduce((acc, char) => {
            const resolvedChar
                = char === 'Space' || char === 'Enter'
                    ? ' '
                    : char
            acc[char] = () => handleExitVariable(resolvedChar)
            return acc
        }, {} as any)

        return {
            ...overloadedKeyboardShortcuts,
            ArrowRight: () => {
                const state = this.editor.state
                const { from, to } = state.selection

                if (from > 1 && from === to) {
                    let variableOnLeft = false
                    state.doc.nodesBetween(from - 1, to - 1, (node) => {
                        const variable = node.marks.find(markItem => markItem.type.name === 'variable')
                        if (variable)
                            variableOnLeft = true
                    })

                    let noVariableUnderCursor = true
                    state.doc.nodesBetween(from, to, (node) => {
                        const variable = node.marks.find(markItem => markItem.type.name === 'variable')
                        if (variable)
                            noVariableUnderCursor = false
                    })

                    let nothingOnRight = true
                    state.doc.nodesBetween(from + 1, to + 1, (node) => {
                        if (node)
                            nothingOnRight = false
                    })

                    if (variableOnLeft && noVariableUnderCursor && nothingOnRight) {
                        return this.editor
                            .chain()
                            .unsetMark('variable')
                            .insertContent([{ type: 'text', text: ' ' }])
                            .run()
                    }
                }

                return false
            },
        }
    },

    addInputRules() {
        return [
            markInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: (match) => {
                    const variable = match[1]
                    return { variable }
                },
            }),
        ]
    },

    addPasteRules() {
        return [
            markPasteRule({
                find: pasteRegex,
                type: this.type,
                getAttributes: (match) => {
                    const variable = match[1]
                    return { variable }
                },
            }),
        ]
    },

})
