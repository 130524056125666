import { relations } from 'drizzle-orm'
import { pgTable, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import type { Static } from '@sinclair/typebox'
import { createSelectSchema } from 'drizzle-typebox'
import { providerCredential } from './provider-credential'

export const provider = pgTable('provider', {
    id: uuid('id').primaryKey().defaultRandom(),
    name: varchar('name', { length: 256 }).notNull().unique(),
    key: text('key').notNull().unique(),
    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
})

export const providerRelations = relations(provider, ({ many }) => ({
    providerCredential: many(providerCredential),
}))

export const selectProviderSchema = createSelectSchema(provider)

export type Provider = Static<typeof selectProviderSchema>
