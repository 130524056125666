import { Type } from '@sinclair/typebox'
import type { Static } from '@sinclair/typebox'
import { boolean, json, pgTable, text, timestamp, uuid, varchar } from 'drizzle-orm/pg-core'
import { relations } from 'drizzle-orm'
import { overrideType, tableToSchema } from './shared'
import { prompt } from './prompt'
import { evaluatorRun } from './evaluator-run'
import { score } from './score'
import { evaluator } from './evaluator'
import { selectPromptVersionMessageSchema } from './prompt-version-message'

export const promptVersion = pgTable('prompt_version', {
    id: uuid('id').primaryKey().defaultRandom(),
    content: text('content'),
    markdownContent: text('markdown_content'),
    promptId: uuid('prompt_id').notNull().references(() => prompt.id, { onDelete: 'cascade' }),
    variables: json('variables').default([]),
    title: varchar('title', { length: 256 }).notNull(),
    configuration: json('configuration').$type<Record<string, any>>(),
    promoted: boolean('promoted').default(false),
    draft: boolean('draft').default(false),

    createdAt: timestamp('created_at').defaultNow(),
    updatedAt: timestamp('updated_at').defaultNow(),
    deletedAt: timestamp('deleted_at'),
})

export const promptVersionRelations = relations(promptVersion, ({ one, many }) => ({
    prompt: one(prompt, {
        fields: [promptVersion.promptId],
        references: [prompt.id],
    }),
    evaluatorRun: many(evaluatorRun),
    evaluator: many(evaluator),
    score: many(score),
}))

export const structuredOutputSchema = Type.Object({
    enabled: Type.Boolean(),
    schema: Type.Optional(Type.Object({
        properties: Type.Record(Type.String(), Type.Any()),
        type: Type.Literal('object'),
        title: Type.String(),
        description: Type.String(),
        required: Type.Array(Type.String()),
    })),
})

export const promptVersionConfigurationSchema = Type.Object({
    model: Type.String(),
    temperature: Type.Optional(Type.Number()),
    type: Type.Enum({ chat: 'chat', completion: 'completion' }),
    structuredOutput: Type.Optional(structuredOutputSchema),
    functions: Type.Optional(Type.Array(Type.Object({
        id: Type.String(),
        name: Type.String(),
        description: Type.Optional(Type.String()),
        parameters: Type.Optional(Type.Object({
            type: Type.Literal('object'),
            properties: Type.Record(Type.String(), Type.Any()),
            required: Type.Optional(Type.Array(Type.String())),
        })),
    }))),
})

export const { selectSchema: selectPromptVersionSchemaRaw, insertSchema: insertPromptVersionSchemaRaw } = tableToSchema(promptVersion)
export const insertPromptVersionSchema = overrideType(
    Type.Omit(insertPromptVersionSchemaRaw, ['createdAt', 'updatedAt', 'deletedAt', 'id']),
    {
        configuration: promptVersionConfigurationSchema,
        variables: Type.Array(Type.String()),
        id: Type.Optional(Type.String()),
    },
)
export const updatePromptVersionSchema = Type.Partial(insertPromptVersionSchema)

export const selectPromptVersionSchema = overrideType(selectPromptVersionSchemaRaw, {
    configuration: promptVersionConfigurationSchema,
    variables: Type.Array(Type.String()),
})

export const queryPromptSchema = Type.Pick(insertPromptVersionSchemaRaw, ['promptId'])

export type PromptVersion = Static<typeof selectPromptVersionSchema>
export type PromptVersionConfiguration = Static<typeof promptVersionConfigurationSchema>
export type PromptVersionConfigurationFunction = NonNullable<PromptVersionConfiguration['functions']>[number]

export const promptVersionWithMessages = Type.Intersect([
    selectPromptVersionSchema,
    Type.Object({ messages: Type.Array(selectPromptVersionMessageSchema) }),
])

export type PromptVersionWithMessages = Static<typeof promptVersionWithMessages>
export type PromptVersionResponse = PromptVersionWithMessages

export type CreatePromptVersionPayload = Static<typeof insertPromptVersionSchema>
export type UpdatePromptVersionPayload = Static<typeof updatePromptVersionSchema>
